import React, { useEffect, useState } from 'react';
import * as api from '@src/utils/mmc-api/api';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { UserData } from '@src/types/User/User';
import CheckoutForm from './Form';

interface StripLazyCheckoutFormProps {
    user: UserData;
}

const isBrowser = typeof window !== 'undefined';

const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`);

const StripLazyCheckoutForm = ({user}: StripLazyCheckoutFormProps) => {

    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        if (user && user?.email) {
          api.stripePay().then(setClientSecret);
        }
    }, [user]);
  
    if (!isBrowser || !clientSecret) {
      return null;
    }

    const options: StripeElementsOptions = {
        clientSecret,
        appearance: {
          theme: 'stripe'
        }
    };

    return (
        <Elements options={options} stripe={stripePromise}>
            <CheckoutForm clientSecret={clientSecret} user={user} />
        </Elements>
    )
};

export default StripLazyCheckoutForm;
