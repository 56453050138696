import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from '@src/components';
import * as api from '@src/utils/mmc-api/api';
import { RootStoreState } from '@src/store';
import { UserData } from '@src/types/User/User';
import StripLazyCheckoutForm from './Form/Lazy';

const Checkout = (props) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const user = useSelector<RootStoreState>((state) => state.user.data) as UserData;
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    if (user && user?.email) {
      fetchFullCart();
    }
  }, [user]);

  useEffect(() => {
    if (user && user?.email) {
      setInitialised(true);
    }
  }, [user])


  async function fetchFullCart() {
    const cart = await api.fullCart();
    setTotalPrice(cart?.totalPrice || 0);
  }

  return (
    <Layout>
      <>
        <>
          <article className="text-gray-700 max-w-screen-md mx-auto p-1">
            <p className="text-xl text-center my-3">
              <span className="p-1 border-b font-bold">Total</span>
              <span className="p-1 border-b ">£{totalPrice}</span>
            </p>
            {initialised ? (
              <StripLazyCheckoutForm user={user} />
            ) : null}
          </article>
        </>
      </>
    </Layout>
  );
};

export default Checkout;
